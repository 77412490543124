// const { init } = require("aos")

//////// A C C O R D I O N S ///////////////////////////////////////

let accor_dt = document.querySelectorAll('dt')
let accor_desc = document.querySelectorAll('dd')
accor_dt.forEach(el => el.addEventListener('click', handleAccordionClicked, false))

initAccordion()

function initAccordion() {
  accor_desc.forEach(el => {
    if (el.classList.contains('hidden')) {
      el.previousElementSibling.querySelector('svg').classList.toggle('rotate')
    }
  })
}

function handleAccordionClicked(e) {
  e.currentTarget.querySelector('svg').classList.toggle('rotate')
  e.currentTarget.nextElementSibling.classList.toggle('hidden')
}

//////// F I L T E R S /////////////////////////////////////////////

let category_switchers = document.querySelectorAll('.category-switch')
category_switchers.forEach(el => el.addEventListener('click', handleCategorySwitchClicked, false))
document.addEventListener("keyup", handlEnterPressed, false)
let category_wraps = document.querySelectorAll('.category-wrap')

function handleCategorySwitchClicked(e) {
  if (!e.currentTarget.nextElementSibling) {
    initialState(e)
    switchSelected(e)
    return 0
  }
  if (e.currentTarget.nextElementSibling.classList.contains('open')) {
    toggleClasses(e)
  } else {
    initialState(e)
    toggleClasses(e)
    switchZIndex(e)
  }
}

function initialState(e) {
  category_wraps.forEach(el => el.classList.remove('open'))
  category_switchers.forEach(el => {
    if (el.querySelector('svg')) {
      el.querySelector('svg').classList.remove('rotate')

    }
  })
}

function toggleClasses(e) {
  switchSelected(e)
  e.currentTarget.nextElementSibling.classList.toggle('open')
  e.currentTarget.querySelector('svg').classList.toggle('rotate')
}

function switchSelected(e) {
  category_switchers.forEach(el => {
    el.classList.remove('selected')
  })
  e.currentTarget.classList.toggle('selected')
}

function switchZIndex(e) {
  category_wraps.forEach(el => el.style.zIndex = 1)
  e.currentTarget.nextElementSibling.style.zIndex = 2
}

function handlEnterPressed(e) {
  if (e.keyCode == 13) {
    category_wraps.forEach(el => el.classList.remove('open'))
  }
}

/////////////// T A B S ////////////////////////////////////////////

let tabs = document.querySelectorAll('button[role="tab"]')
let tabpanels = document.querySelectorAll('div[role="tabpanel"]')

initTabs()

function initTabs() {
  if (tabs.length == 0) {
    console.log("tabs are not present")
    return 0
  } else {
    console.log("tabs are present")
  }
  tabs[0].classList.add('selected')
  tabpanels.forEach(el => el.classList.add('hidden'))
  tabpanels[0].classList.toggle('hidden')
  tabs.forEach(el => el.addEventListener('click', handleTabClicked))
}

function handleTabClicked(e) {
  resetTabs()
  showSelectedPanel(e)
}

function resetTabs() {
  tabs.forEach(el => el.classList.remove('selected'))
  tabpanels.forEach(el => el.classList.remove('hidden'))
  tabpanels.forEach(el => el.classList.add('hidden'))
}

function showSelectedPanel(e) {
  e.currentTarget.classList.add('selected')
  let tabid = e.currentTarget.id
  let tp = Array.from(tabpanels).find(el => el.getAttribute('aria-labelledby') == tabid)
  tp.classList.toggle('hidden')
}

/////////////// B U R G E R ////////////////////////////////////////

let html = document.querySelector('html')
let burger = document.querySelector('.burger')
let burger_i = document.querySelectorAll('.burger img')
let menu_section = document.querySelector('.menu-section')

burger.addEventListener('click', handleBurgerClicked, false)

function handleBurgerClicked(e) {
  menu_section.classList.toggle('active')
  Array.from(burger_i).forEach(el => el.classList.toggle('hidden'))
  html.classList.toggle('fixed')
}

// //////////// S L I D E R S ///////////////////////////////////////

import Splide from '@splidejs/splide';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// //--------------------------------------------------------------//

var check_slider_main = document.querySelector(".slider-main");
if (check_slider_main) {
  console.log(`slider-main is present`);

  var slider_main = new Splide('.slider-main', {
    type: 'loop',
    // autoplay: true,
    interval: 3000,
    speed: 1000,
    perPage: 1,
    focus: 0,
    pagination: true,
    classes: {
      arrow: 'splide__arrow btn-slr',
      prev: 'splide__arrow--prev prev',
      next: 'splide__arrow--next next',
      page: 'splide__pagination__page btn-slr-page',
    },
    breakpoints: {
      680: {
        pagination: false,
      },
    },
  }).mount();

} else {
  console.log(`slider-main is NOT present`);
}

//--------------------------------------------------------------//

var check_slider_news = document.querySelector(".slider-news");
if (check_slider_news) {
  console.log(`slider-news is present`);

  var slider_news = new Splide('.slider-news', {
    type: 'loop',
    autoplay: true,
    interval: 3000,
    speed: 1000,
    perPage: 2.5,
    gap: 20,
    focus: 0,
    pagination: true,
    classes: {
      arrow: 'splide__arrow btn-slr',
      prev: 'splide__arrow--prev prev',
      next: 'splide__arrow--next next',
      pagination: 'splide__pagination slider-news-pagination',
      page: 'splide__pagination__page btn-slr-page-news',
    },
    breakpoints: {
      680: {
        perPage: 1,
      },
    },
  }).mount();

} else {
  console.log(`slider-news is NOT present`);
}

/////////////// M O D A L S /////////////////////////////////////////////////////

//----------- FORM MODAL -----------------------------//

const btn_modal = document.querySelector(".btn--modal")
const btn_close = document.querySelector(".btn--close")

const modal_form_wrap = document.querySelector(".form-modal-wrap")

btn_modal.addEventListener('click', () => {
  modal_form_wrap.showModal()
})
btn_close.addEventListener('click', () => {
  modal_form_wrap.close()
})

modal_form_wrap.addEventListener("click", e => {
  const dialogDimensions = modal_form_wrap.getBoundingClientRect()
  if (
    e.clientX < dialogDimensions.left ||
    e.clientX > dialogDimensions.right ||
    e.clientY < dialogDimensions.top ||
    e.clientY > dialogDimensions.bottom
  ) {
    modal_form_wrap.close()
  }
})

//----------- TEST MODAL -----------------------------//
const btn_test = document.querySelector(".btn--test")
const btn_close_test = document.querySelector(".btn--close-test")

const test_modal_wrap = document.querySelector(".test-modal-wrap")

btn_test.addEventListener('click', () => {
  test_modal_wrap.showModal()
})
btn_close_test.addEventListener('click', () => {
  test_modal_wrap.close()
})

test_modal_wrap.addEventListener("click", e => {
  const dialogDimensions = test_modal_wrap.getBoundingClientRect()
  if (
    e.clientX < dialogDimensions.left ||
    e.clientX > dialogDimensions.right ||
    e.clientY < dialogDimensions.top ||
    e.clientY > dialogDimensions.bottom
  ) {
    test_modal_wrap.close()
  }
})
